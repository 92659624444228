import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './index.scss';

import {
  Section,
  Container,
  Row,
  Column,
  Text,
  Link,
  Image,
} from 'components/atoms';
import Breadcrumbs from 'components/molecules/Breadcrumbs';

const BannerCaseStudy = ({ data, className, breadcrumbsData }) => {
  const { title, acf } = data;
  const { references, logo: banner_logo } = acf || {};
  const {
    url: { alt_text, source_url, media_details, title: image_title },
  } = banner_logo || { url: '' };
  const { width, height } = media_details || {};

  const links =
    references &&
    references.map(({ appearance, link, text, logo }, index) => {
      const { content } = logo || {};

      return (
        <div
          className="banner-case-study__content-description__link"
          key={index}
        >
          {appearance === 'text' ? (
            <Link
              className="banner-case-study__content-description__link-text"
              href={link}
              isRouter={false}
              target="_blank"
              rel="nofollow"
            >
              <Text tag="span" className="body-sm body-sm_bold">
                {text}
              </Text>
            </Link>
          ) : (
            <Link
              className="banner-case-study__content-description__link-logo"
              href={link}
              isRouter={false}
              target="_blank"
              rel="nofollow"
            >
              <div
                className="banner-case-study__content-description__link-logo__image"
                dangerouslySetInnerHTML={{ __html: content }}
              ></div>
            </Link>
          )}
        </div>
      );
    });

  return (
    <Section className={cn('banner-case-study', [className])}>
      <Container>
        <Row>
          <Column className="offset-lg-0" lg="7">
            <Text tag="h1" className="banner-case-study__title headline-2">
              {title}
            </Text>
          </Column>
          <Column
            className="offset-lg-2"
            lg="2"
            onMouseDown={e => e.preventDefault()}
            onContextMenu={e => e.preventDefault()}
            role="button"
            tabIndex="0"
          >
            <Image
              className="banner-case-study__logo"
              src={source_url}
              alt={alt_text}
              title={image_title}
              width={width}
              height={height}
            />
          </Column>
        </Row>
      </Container>

      <Container className="banner-case-study__content-container">
        <Row className="banner-case-study__content">
          {references !== null && (
            <Column
              lg="7"
              className="offset-lg-0 banner-case-study__content-description"
            >
              <div className="banner-case-study__content-description__links d-lg-block">
                {links}
              </div>
            </Column>
          )}
        </Row>
        <Breadcrumbs
          {...breadcrumbsData}
          className="breadcrumbs_case-study"
          isHorizontal={true}
        />
      </Container>
    </Section>
  );
};

BannerCaseStudy.propTypes = {
  data: PropTypes.object.isRequired,
  breadcrumbsData: PropTypes.object,
  className: PropTypes.string,
};

export default BannerCaseStudy;
